<template>
  <section class="basic-page section p-0">
    <div class="overflow-auto">
      <figure :class="style" v-if="info.topVideo || info.image">
        <template v-if="info.topVideo">
          <video
            controls
            disableRemotePlayback
            playsinline
            controlsList="nofullscreen nodownload"
            crossorigin="anonymous"
            preload="metadata"
          >
            <source :src="`${info.topVideo.url}#t=0.1`" />
          </video>
        </template>
        <img
          v-else-if="info.image"
          :src="info.image.sizes.mobile.url || info.image.sizes.thumbnail.url"
        />
      </figure>
      <section class="section content p-5">
        <div v-if="info.title" class="title is-5 mb-3 pb-3">{{ info.title }}</div>
        <div class="text">
          <json-rich-text
            v-if="info.description"
            :text="info.description"
            :key="info.id"
          />
        </div>
      </section>
    </div>

    <div
      class="link-buttons columns is-mobile"
      v-if="info.buttons.length"
    >
      <div
        v-for="(button, index) in info.buttons"
        class="column"
        :class="buttonClass(index)"
        :key="button.id"
      >
        <a
          @click="TrackBtnClick(button.label)"
          :href="button.link"
          class="button is-fullwidth is-primary is-outlined"
          target="_blank"
        >
          <d-icon
            v-if="button.icon"
            :icon="button.icon"
            size="is-small"
            class="mr-1"
          />
          {{ button.label }}
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import JsonRichText from "@/components/JsonRichText.vue";
import "./BasicPage.scss";

export default {
  components: { JsonRichText },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isVideoLoaded: false,
    };
  },
  computed: {
    style() {
      if (this.info.topVideo) return "video";
      else if (this.info.image) return "image is-16by9";
      else return "";
    },
  },
  methods: {
    TrackBtnClick(label) {
      console.log("TrackBtnClick", label);
      this.$TrackEvent("Button Click", {
        label: label,
        experienceId: this.$store.state.user.experienceid,
        experienceName: this.$store.state.user.experience,
        space: this.$store.state.user.space,
      })
    },
    buttonClass(index) {
      if (this.info.buttons.length == 3 && index == 0) return "is-full-mobile";
      if (this.info.buttons.length == 4) return "is-half-mobile";
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";
.basic-page {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;

  .video {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
    video {
      width: 100%;
    }
  }

  .loading-container {
    width: 100%;
    height: 100%;
  }

  .overflow-auto {
    flex-grow: 1;
    height: 1px;

    section.content {
        padding-bottom: 0 !important;
    }

    &:after {

      content: '';
      width: 100%;
      background: linear-gradient(transparent, white);
      display: block;
      height: 2rem;
      position: sticky;
      bottom: 0;
          
    }
  }
  .title {
    border-bottom: solid 1px #e0e0e0;
  }

  img {
    object-fit: cover;
  }

  .column {
    padding: 0.5rem;
  }
  .columns:last-child {
    margin-bottom: 0;
  }
  .link-buttons {
    flex-wrap: wrap;
    margin: 0 !important;
    padding: .5em !important;

    .column {
      padding: .5em;
    }

  }
}
</style>
